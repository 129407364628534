import React from 'react';
import { VStack, Heading, Text, Box, Flex, Button, Icon } from '@chakra-ui/react';
import { FaInstagram, FaTiktok, FaHamburger } from 'react-icons/fa';
import { CopyIcon } from '@chakra-ui/icons';
import { PiCoin } from "react-icons/pi";
import { Collab, Campaign } from '../../../types';

interface CreativeBriefProps {
  collab: Collab;
  campaign: Campaign;
}

const CreativeBrief: React.FC<CreativeBriefProps> = ({ collab, campaign }) => {
  const deliverableTypeToTask: { [key: string]: string } = {
    instagramReels: "Post/Tag on Instagram Reels",
    instagramStories: "Post/Tag on Instagram Story",
    tiktoks: "Post/Tag on TikTok",
    instagramPosts: "Post/Tag on Instagram Feed"
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  const formatDate = (date: any) => {
    if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return 'Invalid Date';
  };

  return (
    <VStack align="stretch" spacing={5}>
      <Heading fontSize="2xl" textAlign="center">Collaboration Details</Heading>
      <Box>
        <Text fontWeight="bold" fontSize={"20px"}>Brief Description</Text>
        <Box borderWidth={1}
            borderColor="gray.300"
            borderRadius="md"
            px={3}
            py={2}
            mt={2}
            width="100%">
        <Text fontSize={"16px"}>{campaign.description}</Text>
        </Box>
      </Box>
      {(collab.credit > 0 || collab.cash > 0) && (
        <Flex direction="column" align="flex-start">
          <Text fontSize="20px" fontWeight="bold">Compensation</Text>
          <Flex align="center" mt={2} width="100%">
          <Flex
            direction="column"
            borderWidth={1}
            borderColor="gray.300"
            borderRadius="md"
            px={3}
            py={2}
            width="100%"
          >
            {collab.credit > 0 && (
              <Flex direction="row" align="center" gap={4}>
                <Text fontSize="20px" fontWeight={"bold"}>🎁</Text>
                <Text fontSize="20px" fontWeight={"bold"}>${collab.credit} Credit</Text>
              </Flex>
            )}
            {collab.cash > 0 && (
                <Flex direction="column">
                  <Flex direction="row" align="center" gap={4}>
                    <Text fontSize="20px" fontWeight={"bold"}>💵</Text>
                    <Text fontSize="20px" fontWeight={"bold"}>${collab.cash} Cash</Text>
                  </Flex>
                  <Text fontSize="14px" color="gray" ml={0}>(Cash paid after content is verified)</Text>
                </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      )}
      <Box>
      <Text fontWeight="bold" fontSize={"20px"}>Deliverables</Text>
      <Box mt={2} borderWidth={1} borderColor="gray.300" borderRadius="md" p={3}>
        <VStack align="stretch" spacing={3}>
          {campaign?.instagramHandle && (
            <Flex align="center">
              <Icon as={FaInstagram} mr={2} />
              <Text fontSize={"16px"}>Tag @{campaign.instagramHandle}</Text>
              <Button size="sm" ml={2} onClick={() => handleCopy(`@${campaign.instagramHandle}`)} variant="ghost">
                <CopyIcon />
              </Button>
            </Flex>
          )}
          {campaign?.tiktokHandle && (
            <Flex align="center">
              <Icon as={FaTiktok} mr={2} />
              <Text fontSize={"16px"}>Tag @{campaign.tiktokHandle}</Text>
              <Button size="sm" ml={2} onClick={() => handleCopy(`@${campaign.tiktokHandle}`)} variant="ghost">
                <CopyIcon />
              </Button>
            </Flex>
          )}
            <VStack align="stretch" spacing={3}>
              {Object.entries(collab.deliverableCounts).filter(([key, value]) => value > 0).map(([key, value]) => (
                <Flex key={key} align="center">
                  <Text as="span" fontWeight="bold" fontSize="16px">{value}</Text>
                  <Text ml={4} fontSize={"16px"}>{deliverableTypeToTask[key] ?? ""}</Text>
                </Flex>
              ))}
            </VStack>
          </VStack>
        </Box>
      </Box>
      <Box>
        {campaign?.locations && campaign.locations.length > 0 && (
          <>
            <Text fontWeight="bold" fontSize={"20px"}>Locations</Text>
            <Box mt={2} borderWidth={1} borderColor="gray.300" borderRadius="md" p={3}>
          <VStack align="stretch" spacing={2}>
            {campaign?.locations?.map((location, index) => (
              <Flex key={index} align="center">
                <Text fontSize={"16px"}>{`${location.streetNumber} ${location.streetName}, ${location.city}, ${location.state}`}</Text>
              </Flex>
            ))}
          </VStack>
            </Box>
          </>
        )}
        <Box>
        {campaign?.campaignType === 'shipToHome' ? (
          <>
            <Text fontWeight="bold" fontSize={"20px"}>Instructions</Text>
            <Flex justify="space-between" >
              <Text fontSize={"18px"}>We will ship the product to you so you can make your content at home!</Text>
            </Flex>
          </>
        ) : (
          campaign?.endDate && (
            <Box mt={2}>
              <Text fontWeight="bold" fontSize={"20px"}>Timeline</Text>
              <Flex justify="space-between">
                <Text fontSize={"18px"}>Come into {campaign.clientName} before <Text as="span" color="red.500">{formatDate(campaign.endDate)}</Text></Text>
              </Flex>
            </Box>
          )
        )}
        </Box>
      </Box>
    </VStack>
  );
};

export default CreativeBrief;