import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from '@chakra-ui/react';

interface ConfirmAlreadyVisitedModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmAlreadyVisitedModal: React.FC<ConfirmAlreadyVisitedModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <ModalHeader>Confirm Visit</ModalHeader>
        <ModalBody>
          <Text>
            By confirming that you've already visited, you'll be able to submit your content. Are you sure you want to continue?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="gray.200"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            bg="black"
            color="white"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmAlreadyVisitedModal;
