import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { InfluencerDataProvider } from './contexts/InfluencerDataContext';
import { CollabDataProvider } from './contexts/collabDataContext';
import { InfluencerCampaignDataProvider } from './contexts/InfluencerCampaignDataContext';
import NewLoginPage from 'features/authentication/NewLogin';
import ReturningUser from 'features/authentication/ReturningUser';
import CollabsPage from './features/collabs/index';
import collabRoutes from './routes/collabRoutes';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { isSignInWithEmailLink } from 'firebase/auth';
import Verification from 'features/authentication/Verification';
import { VerificationProvider } from 'contexts/PhoneVerificationContext';
import RecaptchaInitializer from 'features/collabs/components/RecaptchaInitializer';
import RedeemRewardPage from './features/collabs/pages/RedeemRewardPage';
import ViewRewardPage from './features/collabs/pages/ViewRewardPage';
import SignUpPage from 'features/authentication/NewSignup';
import EmailSentPage from 'features/authentication/EmailSentPage';
import CompleteCollabPage from 'features/collabs/pages/CompleteCollabPage';
import WalletPage from 'features/wallet';
import MainLayout from './components/MainLayout';
import ReviewCollabPage from './features/collabs/pages/ReviewCollabPage';
import ScheduleCollabPage from './features/collabs/pages/ScheduleCollabPage';
import CounterCollabPage from './features/collabs/pages/CounterCollabPage';
const App: React.FC = () => {
  const [user] = useAuthState(auth);
  const isEmailSignInLink = isSignInWithEmailLink(auth, window.location.href);

  return (
    <>
      <RecaptchaInitializer />
      <InfluencerDataProvider>
        <CollabDataProvider>
          <InfluencerCampaignDataProvider> 
            <VerificationProvider>
              <Routes>
                <Route path="/" element={user ? <Navigate to="/collabs" replace /> : <Navigate to="/login" replace />} />
                <Route path="login" element={!user ? <NewLoginPage /> : <Navigate to="/collabs" replace />} />
                <Route path="new-signup" element={!user ? <SignUpPage /> : <Navigate to="/collabs" replace />} />
                <Route path="returning-user" element={!user ? <ReturningUser /> : <Navigate to="/collabs" replace />} />
                <Route path="verification" element={!user ? <Verification /> : <Navigate to="/collabs" replace />} />
                <Route path="email-sent" element={!user ? <EmailSentPage /> : <Navigate to="/collabs" replace />} />

                <Route path="collabs" element={<MainLayout><Outlet /></MainLayout>}>
                  <Route index element={<CollabsPage isFirstTime={!user && isEmailSignInLink} />} />
                  <Route path="review-collab" element={<ReviewCollabPage />} />
                  <Route path="redeem-reward" element={<RedeemRewardPage />} />
                  <Route path="view-reward" element={<ViewRewardPage />} />
                  <Route path="schedule-collab" element={<ScheduleCollabPage />} />
                  <Route path="complete-collab" element={<CompleteCollabPage />} />
                  <Route path="counter-collab" element={<CounterCollabPage />} />
                </Route>
                <Route path="/wallet" element={<MainLayout><WalletPage /></MainLayout>} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </VerificationProvider>
          </InfluencerCampaignDataProvider>
        </CollabDataProvider>
      </InfluencerDataProvider>
    </>
  );
};

export default App;
