import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  Box,
  Button,
  IconButton,
  ModalHeader,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import ExplainDeclineReasonModal from './ExplainDeclineReasonModal';
import CounterOrConfirmModal from './CounterOrConfirmModal';
import ConfirmRejectModal from './ConfirmRejectModal';
import { Campaign, Collab } from 'types';

interface DeclineReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: (reason: string) => void;
  collab: Collab;
  campaign: Campaign;
  maxCounter: number;
}

const DeclineReasonModal: React.FC<DeclineReasonModalProps> = ({ isOpen, onClose, onSubmit, onBack, collab, campaign, maxCounter }) => {
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [isExplainDeclineModalOpen, setIsExplainDeclineModalOpen] = useState(false);
  const [isCounterOrConfirmModalOpen, setIsCounterOrConfirmModalOpen] = useState(false);
  const [isConfirmRejectModalOpen, setIsConfirmRejectModalOpen] = useState(false);
  const navigate = useNavigate();

  const declineOptions = {
    "A":'Not enough compensation',
    "B":'Too many deliverables',
    "C":'Doesn\'t fit my usual brand content',
    "D":'Location is inconvenient',
    "E":'Other'
  };

  const handleOptionClick = (key: string) => {
    const reason = key;
    setSelectedReason(reason === selectedReason ? null : reason);
  };

  const handleSubmit = () => {
    if (selectedReason === "C" || selectedReason === "D") {
      handleConfirmReject();
    } else if (selectedReason === "E") {
      setIsExplainDeclineModalOpen(true); 
      onClose();
    } else {
      if ((campaign.allowCounters != false) && (collab.counters?.length ?? 0) < maxCounter) {
        setIsCounterOrConfirmModalOpen(true);
        onClose();
      } else {
        setIsConfirmRejectModalOpen(true);
        onClose();
      }
    }
  };

  const handleConfirmReject = () => {
    onSubmit(declineOptions[selectedReason as keyof typeof declineOptions]);
    setIsConfirmRejectModalOpen(false);
    onClose();
  }

  const handleExplainSubmit = (explanation: string) => {
    // Handle the submission of the explanation from ExplainReasonModal
    onSubmit(explanation);
    setIsExplainDeclineModalOpen(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxWidth="360px"
          padding="4"
          borderRadius="12px"
          boxShadow="lg"
          bg="white"
        >
          <ModalHeader>
            <IconButton
              icon={<CloseIcon />}
              aria-label="Close"
              onClick={onClose}
              variant="ghost"
              position="absolute"
              left={4}
              top={4}
            />
          </ModalHeader>
          <ModalBody>
            <Text fontSize="2xl" fontWeight="bold" textAlign="left" color="black" mt={2} mb={2}>
              Want to Decline?
            </Text>
            <Text fontSize="xl" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
              Let us know why!
            </Text>
            <VStack align="start" spacing={4}>
              {Object.entries(declineOptions).map(([key, option]) => (
                <Box
                  key={key}
                  borderWidth="1px"
                  borderRadius="md"
                  p={3}
                  w="100%"
                  cursor="pointer"
                  fontSize="small"
                  fontWeight="bold"
                  color="gray.600"
                  onClick={() => handleOptionClick(key)}
                  bg={selectedReason === key ? 'gray.100' : 'white'}
                >
                  {`${key}) ${option}`}
                </Box>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button 
              colorScheme="blackAlpha" 
              onClick={handleSubmit} 
              isDisabled={!selectedReason}
              bg="black"                    
              color="white"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ExplainDeclineReasonModal
        isOpen={isExplainDeclineModalOpen}
        onClose={() => setIsExplainDeclineModalOpen(false)}
        onBack={onBack} // calls onBack to re-open DeclineReasonModal (passed from ReviewCollabPage)
        onSubmit={handleExplainSubmit}
      />

      <CounterOrConfirmModal
        isOpen={isCounterOrConfirmModalOpen}
        onClose={() => setIsCounterOrConfirmModalOpen(false)}
        onBack={onBack}
        onCounter={() => {
          setIsCounterOrConfirmModalOpen(false);
          navigate(`/collabs/counter-collab`, {state: {collab, campaign, reason: declineOptions[selectedReason as keyof typeof declineOptions]}});
        }}
        onConfirmReject={handleConfirmReject}
        maxCounter={maxCounter}
        numTimesCountered={collab?.counters?.length || 0}
      />
      <ConfirmRejectModal
        isOpen={isConfirmRejectModalOpen}
        onClose={() => setIsConfirmRejectModalOpen(false)}
        onConfirmReject={handleConfirmReject}
      />
    </>
  );
};

export default DeclineReasonModal;
