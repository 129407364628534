import React, { useContext, useState } from 'react';
import { Box, Text, Image, Flex, Button, Link, VStack, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import VerificationPendingModal from './VerificationPendingModal';
import CreativeBriefModal from './CreativeBriefModal';
import deliverableNames from '../../../utils/deliverableNames';
import { Collab, Campaign, Influencer } from '../../../types';
import { completeCollab, expireCollab } from '../../../services/firebaseService';
import defaultImage from '../../../assets/foodfluence_default_image.jpg';
import ConfirmAlreadyVisitedModal from './ConfirmAlreadyVisitedModal';
import AddressCollectionModal from './AddressCollectionModal';

interface CollabComponentProps {
  collab: Collab;
  campaign: Campaign;
  influencer: Influencer;
}

const CollabComponent: React.FC<CollabComponentProps> = ({ collab, campaign, influencer }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isVerificationPendingModalOpen, setIsVerificationPendingModalOpen] = useState(false);
  const [isCreativeBriefModalOpen, setIsCreativeBriefModalOpen] = useState(false);
  const [isConfirmAlreadyVisitedModalOpen, setIsConfirmAlreadyVisitedModalOpen] = useState(false);
  const [isAddressCollectionModalOpen, setIsAddressCollectionModalOpen] = useState(false);
  const [randomImage, setRandomImage] = useState<string | null>(null);
  const MAX_COUNTER = 1;
  
  function importAll(r) {
    return r.keys().map(r);
  }
  
  const images = importAll((require as any).context('../../../assets/default_collab_images', false, /\.(png|jpe?g|svg)$/));
  
  const getRandomImage = () => {
    if (!randomImage) {
      const randomIndex = Math.floor(Math.random() * images.length);
      setRandomImage(images[randomIndex]);
      return images[randomIndex];
    }
    return randomImage;
  };

  const handleCollabClick = () => {
    handleActionClick();
  };

  const handleActionClick = () => {
    switch (collab.status) {
      case 'pendingInfluencerApproval':
        navigate(`/collabs/review-collab`, {state: {collab, maxCounter: MAX_COUNTER}});
        break;
      case 'pendingSubmission':
        navigate(`/collabs/complete-collab?id=${collab.id}`); 
        break;
      case 'influencerCountered':
        navigate(`/collabs/counter-collab`, {state: {collab, campaign, influencer}});
        break;
      case 'pendingRedemption':
        const now = new Date();
        if (campaign?.campaignType === "shipToHome") {
          setIsAddressCollectionModalOpen(true);
        } else {
          const scheduledDate = formatDate(collab?.scheduledCollabDate);
          if (scheduledDate) {
          const threeHoursBefore = new Date(scheduledDate.getTime() - 3 * 60 * 60 * 1000);
          const threeHoursAfter = new Date(scheduledDate.getTime() + 3 * 60 * 60 * 1000);
          
          if (now >= threeHoursBefore && now <= threeHoursAfter) {
            navigate(`/collabs/redeem-reward?id=${collab.id}`);
          } else {
            navigate(`/collabs/schedule-collab`, {state: {collab, campaign, influencer}});
          }
        } else {
          navigate(`/collabs/schedule-collab`, {state: {collab, campaign, influencer}});
          }
        }
        break;
      case 'collabRedeemed':
        navigate(`/collabs/view-reward?id=${collab.id}`);
        break;
      case 'pendingVerification':
        setIsVerificationPendingModalOpen(true);
        break;
    }
  };
  
  const handleAlreadyRedeemed = async () => {
    try {
      completeCollab(collab.id, collab.deliverableCounts, true);
      navigate(`/collabs/complete-collab?id=${collab.id}`);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to mark collab as redeemed. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleSecondaryAction = (e: React.MouseEvent, secondaryAction: string) => {
    e.stopPropagation();
    
    if(secondaryAction === 'Already Visited?') {
      setIsConfirmAlreadyVisitedModalOpen(true);
    }
    else if(secondaryAction === 'Reschedule Collab') {
      navigate(`/collabs/schedule-collab`, {state: {collab, campaign, influencer}});
    }
    else {
      setIsCreativeBriefModalOpen(true);
    }
  };

  const actionNames = {
    pendingInfluencerApproval: "Review Collab",
    influencerCountered: "Edit Counter",
    pendingSubmission: "Complete Now",
    pendingVerification: "Awaiting Verification",
    pendingResubmission: "Resubmit Content",
    pendingRedemption: (scheduledDate: Date | null) => {
      if (campaign?.campaignType === "shipToHome") {
        return "Submit Address";
      }
      const now = new Date();
      if (scheduledDate) {
        const threeHoursBefore = new Date(scheduledDate.getTime() - 3 * 60 * 60 * 1000);
        const threeHoursAfter = new Date(scheduledDate.getTime() + 3 * 60 * 60 * 1000);
        if (now >= threeHoursBefore && now <= threeHoursAfter) {
          if (collab.credit > 0) {
            return "Redeem Reward";
          } else {
            return "I'm here!";
          }
        } else {
          return "Reschedule Collab";
        }
      }
      return "Schedule Collab";
    },
    collabRedeemed: "View Reward",
  }

  const secondaryActionName = () => {
    if (collab.status === 'pendingRedemption') {
      const now = new Date();
      const scheduledDate = formatDate(collab.scheduledCollabDate);
      if (scheduledDate) {
        const threeHoursAfter = new Date(scheduledDate.getTime() + 3 * 60 * 60 * 1000);
        const threeHoursBefore = new Date(scheduledDate.getTime() - 3 * 60 * 60 * 1000);
        if (now > threeHoursAfter) {
          return 'Already Visited?';
        } else if (now > threeHoursBefore) {
          return 'Reschedule Collab';
        }
      }
    }
    return 'Collab Details';
  };

  const formatDate = (date: any) => {
    if (date && date.toDate instanceof Function) {
      return date.toDate();
    }
    return null;
  };

  const calculateRemainingTime = (collab: Collab): string =>  {
    if (collab.status !== 'pendingInfluencerApproval' && collab.status !== 'pendingRedemption' && collab.status !== 'collabRedeemed') {
      return '';  // Return empty string for other states
    }

    let startTime, duration;
    if (collab.status === 'pendingInfluencerApproval') {
      startTime = formatDate(collab.collabReceivedTime);
      duration = 72 * 60 * 60 * 1000; // 72 hours in milliseconds
    } else if (collab.status === 'pendingRedemption') {
      if (!collab.scheduledCollabDate) {
      startTime = formatDate(collab.collabAcceptedTime);
      duration = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
      } else {
       return formatDate(collab.scheduledCollabDate).toLocaleString(undefined, { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
      }
    } else if (collab.status === 'collabRedeemed') {
      startTime = formatDate(collab.collabRedeemedTime);
      duration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    }

    if (!(startTime instanceof Date) || isNaN(startTime.getTime())) {
      return 'Invalid Date';
    }

    const endTime = new Date(startTime.getTime() + duration);
    const timeLeft = endTime.getTime() - Date.now();

    if (timeLeft <= 0 && (collab.status === 'pendingInfluencerApproval' || (collab.status==="pendingRedemption" && !collab.scheduledCollabDate) || collab.status==="collabRedeemed")) {
      // expireCollab(collab.id);
      return 'Expiring soon';
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `${days}d ${hours}h`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  };

  return (
    <>
      <Box 
        borderWidth={1} 
        borderRadius="xl" 
        overflow="hidden" 
        boxShadow="md"  
        bg="white" 
        width="275px"
        height="275px" 
        display="flex"
        flexDirection="column"
        m={2}
        onClick={handleCollabClick}
        cursor="pointer"
        _hover={{ boxShadow: "lg" }}
      >
        <Box position="relative">
          <Image 
            src={campaign?.collabImage ?? getRandomImage()} 
            height="120px" 
            width="100%" 
            objectFit="cover"
            onError={(e) => { 
              if (!randomImage) {
                e.currentTarget.src = getRandomImage();
              }
            }}
          />
          {(collab.status === 'pendingInfluencerApproval' || collab.status === 'pendingRedemption' || collab.status === 'collabRedeemed') && (
            <VStack 
              position="absolute" 
              top="2" 
              right="2" 
              bg="rgba(0,0,0,0.6)" 
              color="white" 
              px="2" 
              py="1" 
              borderRadius="md" 
              spacing={0}
              align="flex-end"
            >
              <Text 
                fontSize="xs"
                fontWeight="bold"
              >
                {calculateRemainingTime(collab)}
              </Text>
              {collab.status === 'pendingRedemption' && (
                <Text 
                  fontSize="xs"
                  fontWeight="medium"
                 
                >
                  {(() => {
                    const location = collab.selectedLocation && campaign?.locations?.find(loc => loc.docId === collab.selectedLocation);
                    return location ? `${location.streetNumber} ${location.streetName}, ${location.city}, ${location.state}` : '';
                  })()}
                </Text>
              )}
            </VStack>
          )}
          {(() => {
            const status = collab?.status !== 'pendingInfluencerApproval' ? '' : (collab?.businessRejectedCounter ? 'businessRejectedCounter' : collab?.counters?.length > 0 ? 'counteredByBusiness' : '');
            const statusStyles = {
              "businessRejectedCounter": {
                bg: "rgba(255,0,0,0.8)",
                text: "Counter Rejected"
              },
              "counteredByBusiness": {
                bg: "rgba(255,165,0,0.8)",
                text: "New Counter"
              }
            };

            const style = statusStyles[status];
            if (style) {
              return (
                <Text 
                  position="absolute" 
                  top="2" 
                  left="2" 
                  bg={style.bg} 
                  color="white" 
                  px="2" 
                  py="1" 
                  borderRadius="md" 
                  fontSize="xs"
                  fontWeight="bold"
                >
                  {style.text}
                </Text>
              );
            }
            return null;
          })()}
        </Box>
        <VStack p={3} flex={1} alignItems="stretch" spacing={2}>
          <Flex direction="row" justifyContent="space-between">
            <Text fontSize="md">{campaign?.clientName}</Text>
            <Flex direction="column" alignItems="flex-end">
              {collab.cash > 0 && (
                <Text color="gray.600" fontSize="md">
                  ${collab.cash} Cash
                </Text>
              )}
              {collab.credit > 0 && (
                <Text color="gray.600" fontSize="md">
                  ${collab.credit} Credit
                </Text>
              )}
            </Flex>
          </Flex>
          <Text color="gray.600" fontSize="sm" whiteSpace="normal" wordBreak="break-word">
            Deliverables: {Object.entries(collab.deliverableCounts ?? {})
              .filter(([_, count]) => count > 0)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([type, count]) => `${count}x ${deliverableNames[type].short}`)
              .join(' ')}
          </Text>
          <Flex mt="auto" direction="row" alignItems="center" justifyContent={"space-between"}>
            <Button 
              bg="#C7F5C2A6" 
              color="black" 
              size="xs" 
              onClick={(e) => { e.stopPropagation(); handleActionClick(); }} 
              p={3} 
              alignSelf="flex-start"
            >
              {collab.status === 'pendingRedemption' 
                ? actionNames[collab.status](formatDate(collab.scheduledCollabDate)) 
                : actionNames[collab.status]}
            </Button>
            {collab.status !== 'pendingInfluencerApproval' && (
              <Link
                textDecoration="underline"
                color="black"
                fontSize="sm"
                mb={2}
                onClick={(e) => handleSecondaryAction(e, secondaryActionName())}
              >
                {secondaryActionName()}
              </Link>
            )}
          </Flex>
        </VStack>
      </Box>
      <VerificationPendingModal
        isOpen={isVerificationPendingModalOpen}
        onClose={() => setIsVerificationPendingModalOpen(false)}
        clientName={campaign?.clientName ?? ''}
      />
      <CreativeBriefModal
        isOpen={isCreativeBriefModalOpen}
        onClose={() => setIsCreativeBriefModalOpen(false)}
        collab={collab}
        campaign={campaign}
      />
      <ConfirmAlreadyVisitedModal
        isOpen={isConfirmAlreadyVisitedModalOpen}
        onClose={() => setIsConfirmAlreadyVisitedModalOpen(false)}
        onConfirm={handleAlreadyRedeemed}
      />
      <AddressCollectionModal
        isOpen={isAddressCollectionModalOpen}
        onClose={() => setIsAddressCollectionModalOpen(false)}
        collab={collab}
        campaign={campaign}
      />
    </>
  );
}

export default CollabComponent;