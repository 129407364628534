import {
  collection,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
  DocumentReference,
  FieldValue,
  query,
  where,
  getDocs
} from "firebase/firestore";
import { firestore, auth } from "../firebase";
import { CollabCounter, DeliverableLink, Influencer } from "../types";
import { RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from "firebase/auth";
import { sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

interface CollabLog {
  status: string;
  timestamp: Timestamp;
}
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | undefined;
    confirmationResult: ConfirmationResult | undefined;
  }
}

const updateCollabStatus = async (collabId: string, newStatus: string, additionalFields: Record<string, any> = {}): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    const now: Timestamp = Timestamp.now();
    
    const updateData: {
      status: string;
      logs: CollabLog[] | FieldValue;
    } = {
      status: newStatus,
      logs: arrayUnion({
        status: newStatus,
        timestamp: now
      }),
      ...additionalFields
    };

    await updateDoc(collabRef, updateData);
    console.log(`Collab ${newStatus} successfully`);
  } catch (error) {
    console.error(`Error updating collab status to ${newStatus}:`, error);
    throw error;
  }
};



export const expireCollab = async (collabId: string): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "collabExpired");
    console.log('Collab expired successfully');
  } catch (error) {
    console.error('Error expiring collab:', error);
    throw error;
  }
};

export const rejectCollab = async (collabId: string, reason?: string): Promise<void> => {
  try {
    if (reason) {
      await updateCollabStatus(collabId, "collabCanceled", {influencerRejectionReason: reason});
    } else {
      await updateCollabStatus(collabId, "collabCanceled");
    }
    console.log('Collab rejected successfully');
  } catch (error) {
    console.error('Error rejecting collab:', error);
    throw error;
  }
};

export const acceptCollab = async (collabId: string): Promise<void> => {
  try {
    const now: Timestamp = Timestamp.now();
    const newStatus = "pendingRedemption";
    await updateCollabStatus(collabId, newStatus, { collabAcceptedTime: now });

    console.log(`Collab accepted successfully`);
  } catch (error) {
    console.error(`Error accepting collab:`, error);
    throw error;
  }
};

export const acceptAtHomeCollab = async (collabId: string, shippingAddress: string, deliverableCounts: Record<string, number>): Promise<void> => {
  try {
    const deliverableLinks: { [key: string]: DeliverableLink[] } = {};
    for (const [type, count] of Object.entries(deliverableCounts)) {
      deliverableLinks[type] = Array(count).fill({ userLink: '', verifiedUGCLink: '', status: 'pendingSubmission' });
    }
    
    const now: Timestamp = Timestamp.now();
    const newStatus = "pendingSubmission";

    await updateCollabStatus(collabId, newStatus, { collabAcceptedTime: now, collabRedeemedTime: now, shippingAddress, deliverableLinks });
  } catch (error) {
    console.error('Error accepting collab and sending address:', error);
    throw error;
  }
};

export const submitCollab = async (collabId: string, deliverableLinks: { [key: string]: DeliverableLink[] }): Promise<void> => {
  try {
    await updateCollabStatus(collabId, "pendingVerification", { deliverableLinks });
    console.log('Collab submitted successfully');
  } catch (error) {
    console.error('Error submitting collab:', error);
    throw error;
  }
};

export const submitLink = async (
  collabId: string,
  linkType: string,
  link: string,
  linkIndex: number,
  deliverableLinks: { [key: string]: DeliverableLink[] }
): Promise<void> => {
  try {
    // Check if the specific link exists
    if (!deliverableLinks[linkType] || !deliverableLinks[linkType][linkIndex]) {
      throw new Error("Link not found");
    }

    // Update the specific link
    deliverableLinks[linkType][linkIndex] = {
      ...deliverableLinks[linkType][linkIndex],
      userLink: link,
      status: "pendingVerification"
    };

    // Update the document in Firestore
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, { deliverableLinks });

    console.log(`Link ${linkType} at index ${linkIndex} submitted successfully`);
  } catch (error) {
    console.error(`Error submitting link ${linkType} at index ${linkIndex}:`, error);
    throw error;
  }
};

export const redeemCollab = async (collabId: string): Promise<void> => {
  try {
    const now: Timestamp = Timestamp.now();
    await updateCollabStatus(collabId, "collabRedeemed", { collabRedeemedTime: now });
    console.log('Collab redeemed successfully');
  } catch (error) {
    console.error('Error redeeming collab:', error);
    throw error;
  }
};

export const completeCollab = async (collabId: string, deliverableCounts: Record<string, number>, skipRedeem: boolean = false): Promise<void> => {
  try {
    const deliverableLinks: { [key: string]: DeliverableLink[] } = {};
    for (const [type, count] of Object.entries(deliverableCounts)) {
      deliverableLinks[type] = Array(count).fill({ userLink: '', verifiedUGCLink: '', status: 'pendingSubmission' });
    }
    const additionalFields: {deliverableLinks: { [key: string]: DeliverableLink[] }, collabRedeemedTime?: Timestamp} = {deliverableLinks};

    if (skipRedeem) {
      additionalFields.collabRedeemedTime = Timestamp.now();
    }

    await updateCollabStatus(collabId, "pendingSubmission", additionalFields);
    console.log('Collab completed successfully');
  } catch (error) {
    console.error('Error completing collab:', error);
    throw error;
  }
};

export const counterCollab = async (collabId: string, deliverableCounts: Record<string, number>, cash: number, credit: number, reason: string, previousCounters: CollabCounter[]): Promise<void> => {
  try {
    const newCounters = [...previousCounters, {reason, proposedInfluencerDeliverableCounts: deliverableCounts, proposedInfluencerCash: cash, proposedInfluencerCredit: credit, timestamp: Timestamp.now() }];
    await updateCollabStatus(collabId, "influencerCountered", {
      proposedInfluencerDeliverableCounts: deliverableCounts,
      proposedInfluencerCash: cash,
      proposedInfluencerCredit: credit,
      counters: newCounters,
      collabCounteredTime: Timestamp.now()
    });

    console.log('Collab countered successfully');
  } catch (error) {
    console.error('Error countering collab:', error);
    throw error;
  }
};
export const updateCollabCounter = async (collabId: string, deliverableCounts: Record<string, number>, cash: number, credit: number, counters: CollabCounter[]): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);

    if (counters.length > 0) {
      const mostRecentCounterIndex: number = counters.reduce((latestIndex: number, currentCounter: CollabCounter, currentIndex: number) => 
        currentCounter.timestamp > counters[latestIndex].timestamp ? currentIndex : latestIndex
      , 0);
      counters[mostRecentCounterIndex].proposedInfluencerDeliverableCounts = deliverableCounts;
      counters[mostRecentCounterIndex].proposedInfluencerCash = cash;
      counters[mostRecentCounterIndex].proposedInfluencerCredit = credit;
      counters[mostRecentCounterIndex].timestamp = Timestamp.now();
    }

    await updateDoc(collabRef, {
      proposedInfluencerDeliverableCounts: deliverableCounts,
      proposedInfluencerCash: cash,
      proposedInfluencerCredit: credit,
      counters: counters
    });
    console.log('Collab counter updated successfully');
  } catch (error) {
    console.error('Error updating collab counter:', error);
    throw error;
  }
};

export const updateCollabDate = async (collabId: string, selectedDate: Date, selectedLocation?: string): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    const updateData: { scheduledCollabDate: Timestamp, selectedLocation?: string } = { scheduledCollabDate: Timestamp.fromDate(selectedDate) };
    
    if (selectedLocation) {
      updateData.selectedLocation = selectedLocation;
    }
    
    await updateDoc(collabRef, updateData);
    console.log("Collab date updated successfully");
  } catch (error) {
    console.error("Error updating collab date:", error);
    throw error;
  }
};

export const updateDeliverableLinks = async (collabId: string, deliverableLinks: { [key: string]: DeliverableLink[] }): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, { deliverableLinks });
    console.log("Deliverable links updated successfully");
  } catch (error) {
    console.error("Error updating deliverable links:", error);
    throw error;
  }
};

export const updateCollabUserRedemptionConfirmation = async (collabId: string, userConfirmation: boolean): Promise<void> => {
  try {
    const collabRef: DocumentReference = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, {
      userConfirmRedemption: userConfirmation ? "yes" : "no"
    });
    console.log('User confirmation updated successfully');
  } catch (error) {
    console.error('Error updating user confirmation:', error);
    throw error;
  }
};

export const checkInfluencerEmail = async (email: string): Promise<boolean> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("email_address", "==", email.toLowerCase()));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error("Error checking influencer email:", error);
    throw error;
  }
};

export const checkInfluencerPhone = async (phoneNumber: string): Promise<boolean> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error("Error checking influencer phone:", error);
    throw error;
  }
};

export const sendEmailVerificationCode = async (email: string, phoneNumber: string): Promise<boolean> => {
  try {
    const actionCodeSettings = {
      url: `${window.location.origin}/collabs?email=${encodeURIComponent(email)}&phoneNumber=${encodeURIComponent(phoneNumber)}`,
      handleCodeInApp: true,
    };
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    
    return true;
  } catch (error) {
    console.error("Error sending email verification code:", error);
    return false;
  }
};

export const sendPhoneVerificationCode = async (phoneNumber: string): Promise<any> => {
  try {
    if (!window.recaptchaVerifier) {
      throw new Error("reCAPTCHA not initialized");
    }
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
    return confirmationResult;
  } catch (error) {
    console.error("Error sending phone verification code:", error);
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = undefined;
    }
    throw error;
  }
};

export const verifyEmailCode = async (): Promise<boolean> => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const phoneNumber = urlParams.get("phoneNumber");

    if (isSignInWithEmailLink(auth, window.location.href)) {
      await signInWithEmailLink(auth, email, window.location.href);

      if (phoneNumber) {
        const user = auth.currentUser;
        if (user) {
          const influencer = await getInfluencerByEmail(email);
          if (influencer) {
            await updatePhoneNumber(influencer.id, phoneNumber);
            console.log('Phone number added to the database successfully');
          } else {
            console.error('No influencer found for the given email.');
          }
        }
      }
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error verifying email code:", error);
    return false;
  }
};

export const verifyPhoneCode = async (confirmationResult: ConfirmationResult, code: string): Promise<boolean> => {
  try {
    if (!confirmationResult) {
      throw new Error("Confirmation result not found");
    }
   
    const credential = await confirmationResult.confirm(code);
    return !!credential.user;
  } catch (error) {
    console.error("Error verifying phone code:", error);
    return false;
  }
};

export const getInfluencerByPhoneNumber = async (phoneNumber: string): Promise<Influencer | null> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const influencerDoc = querySnapshot.docs[0];
      return { id: influencerDoc.id, ...influencerDoc.data() } as Influencer;
    }
    return null;
  } catch (error) {
    console.error("Error fetching influencer by phone number:", error);
    throw error;
  }
};

export const getInfluencerByEmail = async (email: string): Promise<Influencer | null> => {
  try {
    const influencersRef = collection(firestore, "influencers");
    const q = query(influencersRef, where("email_address", "==", email.toLowerCase()));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const influencerDoc = querySnapshot.docs[0];
      return { id: influencerDoc.id, ...influencerDoc.data() } as Influencer;
    }
    return null;
  } catch (error) {
    console.error("Error fetching influencer by email:", error);
    throw error;
  }
};

export const updatePhoneNumber = async (influencerId: string, newPhoneNumber: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerId);
    await updateDoc(influencerRef, {
      phoneNumber: newPhoneNumber
    });
    console.log('Phone number updated successfully');
  } catch (error) {
    console.error('Error updating phone number:', error);
    throw error;
  }
};

export const updateFirstName = async (influencerId: string, newFirstName: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerId);
    await updateDoc(influencerRef, {
      firstName: newFirstName
    });
    console.log('First name updated successfully');
  } catch (error) {
    console.error('Error updating first name:', error);
    throw error;
  }
};

export const updateLastName = async (influencerId: string, newLastName: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerId);
    await updateDoc(influencerRef, {
      lastName: newLastName
    });
    console.log('Last name updated successfully');
  } catch (error) {
    console.error('Error updating last name:', error);
    throw error;
  }
};

export const updateEmailAddress = async (influencerId: string, newEmailAddress: string): Promise<void> => {
  try {
    const influencerRef = doc(firestore, "influencers", influencerId);
    await updateDoc(influencerRef, {
      email_address: newEmailAddress.toLowerCase()
    });
    console.log('Email address updated successfully');
  } catch (error) {
    console.error('Error updating email address:', error);
    throw error;
  }
};

export const claimCollabPayment = async (collabId: string): Promise<void> => {
  try {
    const collabRef = doc(firestore, "collabs", collabId);
    await updateDoc(collabRef, {
      paymentClaimed: true,
    });
    console.log('Payment claimed successfully');
  } catch (error) {
    console.error('Error claiming payment:', error);
    throw error;
  }
};


