import React, { useContext, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, Text, useToast } from '@chakra-ui/react';
import { submitLink } from 'services/firebaseService';
import { CollabDataContext } from 'contexts/collabDataContext';
import { useLocation } from 'react-router-dom';
import { sendNotificationToVerifyContent } from 'services/flaskService';
import { InfluencerDataContext } from 'contexts/InfluencerDataContext';

interface InputLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  deliverableKey: string;
  postLink: string;
  deliverableLinks: { [key: string]: any[] };
  linkIndex: number;
  showFeedback: boolean;
  rejectionReason: string | null;
  modalHeader: string; // Add this prop to receive the header
  clientName: string;
}

const InputLinkModal: React.FC<InputLinkModalProps> = ({ isOpen, onClose, deliverableKey, postLink, deliverableLinks, linkIndex, showFeedback, rejectionReason, modalHeader, clientName }) => {
  const toast = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collabId = queryParams.get('id');
  const collabData = useContext(CollabDataContext);
  const collab = collabData.find(c => c.id === collabId);
  const{influencerData} = useContext(InfluencerDataContext);
  const [newPostLink, setNewPostLink] = useState(postLink);

  const handleConfirm = async () => {
    if (newPostLink.trim() === '') {
      toast({
        title: "Link is required.",
        description: "Please enter a valid link before confirming.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await submitLink(collab.id, deliverableKey, newPostLink, linkIndex, deliverableLinks);
      sendNotificationToVerifyContent(influencerData.instagramHandle, clientName);
      toast({
        title: "Link submitted.",
        description: "Your link has been submitted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setNewPostLink('');
      onClose();
    } catch (error) {
      toast({
        title: "Submission failed.",
        description: "There was an error submitting your link.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="300px" mx="auto">
        <ModalHeader>{modalHeader}</ModalHeader> {/* Use the passed header */}
        <ModalBody>
          {showFeedback && (
            <>
              <Text color="red.500" mb={2}>
                Please resubmit according to the following feedback:
              </Text>
              <Text color="red" mb={10} fontWeight="bold">
                {rejectionReason}
              </Text>
            </>
          )}
          <Text mb={2}>Paste a link to your post below:</Text>
          <Input
            placeholder="Enter your post link"
            value={newPostLink}
            onChange={(e) => setNewPostLink(e.target.value)}
          />
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            bg="black"
            color="white"
            onClick={handleConfirm}
            isDisabled={newPostLink.trim() === ''}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InputLinkModal;
