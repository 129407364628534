import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface ConfirmRejectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmReject: () => void;
}

const ConfirmRejectModal: React.FC<ConfirmRejectModalProps> = ({ isOpen, onClose, onConfirmReject }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth="360px"
        padding="4"
        borderRadius="12px"
        boxShadow="lg"
        bg="white"
      >
        <ModalHeader>
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Back"
            onClick={onClose}
            variant="ghost"
            position="absolute"
            left={4}
            top={4}
          />
        </ModalHeader>
        <ModalBody>
          <Text fontSize="2xl" fontWeight="bold" textAlign="left" color="black" mt={2} mb={2}>
            Are you sure you want to decline?
          </Text>
          <Text fontSize="xl" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
            Once declined, this offer will not be accessible.
          </Text>
          <Text fontSize="xl" fontWeight="normal" textAlign="left" color="red" mb={4}>
            You will not be able to counter this offer.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blackAlpha"
            onClick={onClose}
            bg="black"
            color="white"
            _hover={{ bg: "blackAlpha.900" }}
            flex="1"
            mr="2"
          >
            Cancel
          </Button>
          <Button
            colorScheme="blackAlpha"
            onClick={onConfirmReject}
            bg="black"
            color="white"
            _hover={{ bg: "blackAlpha.900" }}
            flex="1"
            mr="2"
          >
            Confirm Reject
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmRejectModal;
