// src/types.ts

import { Timestamp } from "firebase/firestore";

export interface Collab {
    id: string;
    description: string;
    credit: number;
    cash: number;
    deliverableCounts: {
      [key: string]: number;
    };
    deliverableLinks: {
      [key: string]: DeliverableLink[];
    };
    status: CollabStatus;
    collabImage: string;
    rejectionReason?: string;
    influencerID: string;
    influencerCampaignID: string;
    userConfirmRedemption: string;
    collabReceivedTime: Date;
    collabAcceptedTime: Date;
    collabVerifiedTime: Date;
    collabRedeemedTime: Date;
    scheduledCollabDate: Timestamp;
    paymentClaimed: boolean;
    selectedLocation?: string;
    shippingAddress?: string;
    proposedInfluencerDeliverableCounts: {
      [key: string]: number;
    };
    proposedInfluencerCash: number;
    proposedInfluencerCredit: number;
    counters?: Array<{
      reason: string | string[];
      timestamp: Timestamp;
      proposedDeliverables?: Record<string, number>;
      proposedCash?: number;
      proposedCredit?: number;
    }>;
    businessRejectedCounter?: boolean;
  }
  
  export type CollabStatus = 
    'pendingInfluencerApproval' | 
    'influencerCountered' |
    'pendingSubmission' | 
    'pendingResubmission' | 
    'pendingVerification' | 
    'collabCanceled' | 
    'pendingRedemption' | 
    'collabRedeemed' | 
    'collabCompleted' |
    'collabExpired' 
  
  export interface Campaign {
    id: string;
    name: string;
    description: string;
    clientID: string;
    endDate: Timestamp;
    startDate: Timestamp;
    rewardType: string,
    expirationTime: number,
    collabImage: string
    clientName: string;
    instagramHandle: string;
    tiktokHandle: string;
    openHours: { [key: string]: string[] };
    clientEmail: string;
    profilePicURL: string;
    campaignType?: 'shipToHome' | 'in_store' | 'shopify';
    foodfluenceLocations?: string[];
    locations?: FoodfluenceLocation[];
    allowCounters?: boolean; 
    counters?: CollabCounter[];
  }
  
  export interface Influencer {
    id: string;
    firstName: string;
    lastName: string;
    instagramHandle: string;
    instagramId: string;
    email_address: string;
    shippingAddress: string;
    phoneNumber: string;
    countryCode: string;
    follower_count: number;
    engagementRate: number;
    profile_pic_url: string;
  }

export type DeliverableLink = {
  userLink: string;
  verifiedUGCLink: string;
  status: string;
  rejectionReason?: string;
};

export interface Client {
  id: string;
  name: string;
  instagramHandle: string;
  tiktokHandle: string;
}

export interface FoodfluenceLocation {
  docId: string;
  id: string;
  fullAddress: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  lat: number;
  lng: number;
  timezone: string;
  emails: string[];
  clientID: string;
}

export const countryOptions = [
  { code: '+1', name: 'United States/Canada' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+33', name: 'France' },
  { code: '+49', name: 'Germany' },
  { code: '+34', name: 'Spain' },
  { code: '+39', name: 'Italy' },
  { code: '+351', name: 'Portugal' },
  { code: '+353', name: 'Ireland' },
  { code: '+31', name: 'Netherlands' },
  { code: '+32', name: 'Belgium' },
  { code: '+41', name: 'Switzerland' },
  { code: '+46', name: 'Sweden' },
  { code: '+47', name: 'Norway' },
  { code: '+45', name: 'Denmark' },
  { code: '+358', name: 'Finland' },
  { code: '+48', name: 'Poland' },
  { code: '+43', name: 'Austria' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+36', name: 'Hungary' },
  { code: '+30', name: 'Greece' },
  { code: '+7', name: 'Russia' },
  { code: '+81', name: 'Japan' },
  { code: '+82', name: 'South Korea' },
  { code: '+86', name: 'China' },
  { code: '+852', name: 'Hong Kong' },
  { code: '+65', name: 'Singapore' },
  { code: '+91', name: 'India' },
  { code: '+971', name: 'United Arab Emirates' },
  { code: '+966', name: 'Saudi Arabia' },
  { code: '+20', name: 'Egypt' },
  { code: '+27', name: 'South Africa' },
  { code: '+55', name: 'Brazil' },
  { code: '+52', name: 'Mexico' },
  { code: '+54', name: 'Argentina' },
  { code: '+56', name: 'Chile' },
  { code: '+57', name: 'Colombia' },
  { code: '+51', name: 'Peru' },
  { code: '+58', name: 'Venezuela' },
  { code: '+61', name: 'Australia' },
  { code: '+64', name: 'New Zealand' },
  { code: '+62', name: 'Indonesia' },
  { code: '+60', name: 'Malaysia' },
  { code: '+66', name: 'Thailand' },
  { code: '+84', name: 'Vietnam' },
  { code: '+63', name: 'Philippines' }
];

type Reason = {
  description: string;
  timestamp: Date; // or string, depending on how you handle timestamps
};

export interface CollabCounter {
  reason: string | string[];
  timestamp: Timestamp;
  proposedInfluencerDeliverableCounts?: Record<string, number>;
  proposedInfluencerCash?: number;
  proposedInfluencerCredit?: number;
}