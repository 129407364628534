import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Textarea, Text, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface ExplainReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBack: () => void; 
  onSubmit: (explanation: string) => void;
}

const ExplainDeclineReasonModal: React.FC<ExplainReasonModalProps> = ({ isOpen, onClose, onBack, onSubmit }) => {
  const [explanation, setExplanation] = React.useState('');

  const handleSubmit = () => {
    onSubmit(explanation);
    onClose();
  };

  // for the back arrow
  const handleBackAndClose = () => {
    onBack();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth="360px"          
        padding="4"              
        borderRadius="12px"       
        boxShadow="lg"            
        bg="white"                
      >
        <ModalHeader>
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Back"
            onClick={handleBackAndClose} 
            variant="ghost"
            position="absolute"
            left={4}
            top={4}
          />
        </ModalHeader>
        <ModalBody>
          <Text fontSize="2xl" fontWeight="bold" textAlign="left" color="black" mt={2} mb={2}>
            Want to Decline?
          </Text>
          <Text fontSize="xl" fontWeight="normal" textAlign="left" color="gray.600" mb={4}>
            Let us know why!
          </Text>
          <Textarea
            placeholder="Optional: Please explain your reason for declining."
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
            borderColor="black"           
            borderRadius="md"              
            mb={4}                         
            minHeight="120px"              
            _focus={{ borderColor: "black" }} 
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blackAlpha"       
            onClick={handleSubmit}
            bg="black"                     
            color="white"                  
            _hover={{ bg: "gray.700" }}
            isDisabled={explanation.length === 0}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExplainDeclineReasonModal;
