import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Box,
  List,
  ListItem,
  IconButton,
  Flex,
  Text,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { getPlaceSuggestions, getPlaceDetails, sendAddressSubmissionEmail } from '../../../services/flaskService';
import debounce from 'lodash/debounce';
import { acceptAtHomeCollab } from 'services/firebaseService';
import { useNavigate } from 'react-router-dom';
import { Collab, Campaign, Influencer } from 'types';
import { InfluencerDataContext } from 'contexts/InfluencerDataContext';

interface AddressCollectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  collab: Collab;
  campaign: Campaign;
}


const AddressCollectionModal: React.FC<AddressCollectionModalProps> = ({
  isOpen,
  onClose,
  collab,
  campaign,
}) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const {influencerData} = useContext(InfluencerDataContext) as {influencerData: Influencer | null};

  const toast = useToast();

  const navigate = useNavigate();

  const fetchSuggestions = async (input: string) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }
    try {
      const results = await getPlaceSuggestions(input);
      if (results.length === 0) {
        toast({
          title: "No suggestions found",
          description: "Please try a different address",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      }
      setSuggestions(results);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debouncedFetchSuggestions = useCallback(debounce(fetchSuggestions, 300), []);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddress(value);
    debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = async (suggestion: any) => {
    try {
      const placeDetails = await getPlaceDetails(suggestion.placeId);
      if (!placeDetails) {
        toast({
          title: "Error",
          description: "Failed to get address details. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      const { streetNumber, streetName, city, state, zip } = placeDetails;
      setAddress(`${streetNumber} ${streetName}, ${city}, ${state}, ${zip}`);
      setSuggestions([]);
      setShowSuggestions(false);
    } catch (error) {
      console.error('Error handling suggestion click:', error);
      toast({
        title: "Error",
        description: "Failed to process address selection. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      await acceptAtHomeCollab(collab.id, address, collab.deliverableCounts);

      onClose();
      await sendAddressSubmissionEmail(collab.id, campaign.clientEmail, influencerData?.instagramHandle);

      toast({
        title: "Address submitted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error entering address:', error);
      toast({
        title: "Error entering address",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="space-between" flexDirection="column">
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            Enter Your Shipping Address
            <IconButton
              icon={<CloseIcon />}
              aria-label="Close modal"
              onClick={onClose}
              variant="ghost"
            />
          </Flex>
          <Text fontSize="lg" color="gray.600" mt={2} >
            {campaign?.clientName} will send the product to this address
          </Text>
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired position="relative">
              <FormLabel>Address</FormLabel>
              <Input
                value={address}
                onChange={handleAddressChange}
                placeholder="Start typing your address..."
                borderColor="gray.300"
                _hover={{ borderColor: "gray.400" }}
                _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px blue.500" }}
              />
              {showSuggestions && suggestions.length > 0 && (
                <List
                  position="absolute"
                  top="100%"
                  left={0}
                  right={0}
                  bg="white"
                  boxShadow="md"
                  borderRadius="md"
                  maxH="200px"
                  overflowY="auto"
                  zIndex={1000}
                >
                  {suggestions.map((suggestion, index) => (
                    <ListItem
                      key={index}
                      p={2}
                      cursor="pointer"
                      _hover={{ bg: "gray.100" }}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.description}
                    </ListItem>
                  ))}
                </List>
              )}
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="black"
            color="white"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            _hover={{ bg: "blue.600" }}
            isDisabled={address.length === 0}
            mr={3}
          >
            Submit
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddressCollectionModal;