import { Timestamp } from "firebase/firestore";
import Environment from "../environment";

const API_KEY = Environment.FLASK_API_KEY;
const HOST_URL = Environment.FLASK_HOST_URL;

//methods


//you have 24 hours to accept your collab

//your collab has expired

//your collab

//your content was approved/rejected

//post receivign reward reminder to post with delivera

//please remember to post your content and get verified

export const acceptCollabReminder = async (collabId: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/accept-collab/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        collab_id: collabId
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error accepting collab:', error.message);
    } else {
      console.error('Error accepting collab:', error);
    }
    return false;
  }
};

export const scheduleCollabReminder = async (
  collabId: string,
  scheduledTime: string,
  isDateAlreadyScheduled: boolean,
  clientName: string,
  influencerInstagramHandle: string,
  influencerPhoneNumber: string,
  clientEmail: string,
  timezone: string,
  location: string,
  locationEmails: string[]
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/schedule-collab/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        collab_id: collabId,
        scheduled_time: scheduledTime,
        is_date_already_scheduled: isDateAlreadyScheduled,
        client_name: clientName,
        influencer_phone: influencerPhoneNumber,
        influencer_handle: influencerInstagramHandle,
        client_email: clientEmail,
        timezone: timezone,
        location: location,
        location_emails: locationEmails
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error scheduling collab reminder:', error.message);
    } else {
      console.error('Error scheduling collab reminder:', error);
    }
    return false;
  }
};

export const processCollabPayment = async (
  collabIds: string,
  amount: number,
  influencerPhoneNumber: string,
  influencerCountryCode: string,
  influencerHandle: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/send-payment-link/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        phone_number: influencerPhoneNumber,
        country_code: influencerCountryCode,
        payment_amount: amount,
        collab_ids: collabIds,
        influencer_handle: influencerHandle
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error processing payment:', error.message);
    } else {
      console.error('Error processing payment:', error);
    }
    return false;
  }
};

export const sendNotificationToVerifyContent = async (
  influencerHandle: string,
  clientName: string
): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/influencer-submitted-link/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        client_name: clientName,
        influencer_handle: influencerHandle
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending notification to verify content:', error.message);
    } else {
      console.error('Error sending notification to verify content:', error);
    }
    return false;
  }
};

export const getPlaceSuggestions = async (inputText: string): Promise<any[]> => {
  try {
    const response = await fetch(`${HOST_URL}/places/autocomplete?input=${encodeURIComponent(inputText)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error getting place suggestions:', error.message);
    } else {
      console.error('Error getting place suggestions:', error);
    }
    return [];
  }
};

export const getPlaceDetails = async (placeId: string): Promise<any> => {
  try {
    const response = await fetch(`${HOST_URL}/places/details?place_id=${encodeURIComponent(placeId)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error getting place details:', error.message);
    } else {
      console.error('Error getting place details:', error);
    }
    return null;
  }
};

export const sendAddressSubmissionEmail = async (collabId: string, clientEmail: string, influencerHandle: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/send-influencer-submitted-address-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({ 
        collab_id: collabId,
        client_email: clientEmail,
        influencer_handle: influencerHandle
      })
    }); 

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending address submission email:', error.message);
    } else {
      console.error('Error sending address submission email:', error);
    }
    return false;
  }
};

export const sendCounterEmail = async (clientEmail: string, influencerHandle: string): Promise<boolean> => {
  try {
    const response = await fetch(`${HOST_URL}/influencer-countered/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${API_KEY}`
      },
      body: JSON.stringify({
        client_email: clientEmail,
        influencer_handle: influencerHandle
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result.success;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error sending counter email:', error.message);
    } else {
      console.error('Error sending counter email:', error);
    }
    return false;
  }
};  